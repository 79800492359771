import type { Price } from '@/types/schema';

export interface Pricing {
	prices: Price[],
	grandTotal: number,
	subTotal: number,
	taxTotal: number,
	paidTotal?: number,
	taxPercent: number,
	remainingAmount: number
}

export default function GetPricing( data ): Pricing {
	const tipsTotal = data?.payments?.reduce( ( sum, payment ) => {
		if ( payment.tip > 0 ) {
			sum += payment.tip;
		}
		return sum;
	}, 0 );
	
	return {
		prices         : data.prices?.filter( ( price ) => !price.metadata?.cloverTaxPercent ),
		grandTotal     : data.grandTotal,
		subTotal       : data.subTotal,
		taxPercent     : data.taxPercent,
		paidTotal      : data.paidTotal,
		remainingAmount: data?.grandTotal - data?.paidTotal,
		taxTotal       : data.taxTotal + ( tipsTotal || 0 ),
	} as Pricing;
}
