import PageSection from '@/components/page/section';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function SignatureLine( {
	layoutColor,
	isPdf,
}: {
	layoutColor?: string,
	isPdf?: boolean
} ) {
	const { t } = useTranslation();
	
	return (
		<PageSection
			primary={isPdf ? 'Sign' : t( 'commerce:sign' )}
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			<Stack spacing={5} direction='row'>
				<Box sx={{ width: '50%', pt: 5 }}>
					<Divider sx={{ borderStyle: 'dashed', mb: 1 }}/>
					<Typography color='text.secondary'>{isPdf ? 'Full name' : t( 'common:full-name-signature' )}</Typography>
				</Box>
				<Box sx={{ width: '50%', pt: 5, mb: 3 }}>
					<Divider sx={{ borderStyle: 'dashed', mb: 1 }}/>
					<Typography color='text.secondary'>{isPdf ? 'Sign here' : t( 'common:sign-here' )}</Typography>
				</Box>
			</Stack>
		</PageSection>
	);
}

