import { useGraphQL } from '@/data';
import { InvoicesBalance } from '@/data/commerce/invoice.graphql';
import type { Order, Staff } from '@/types/schema';
import { QueryInvoicesBalanceArgs } from '@/types/schema';
import { InvoiceLayoutTemplateType, LayoutColorMode, LineItemLayoutType } from '@/types/templates';
import { useTheme } from '@mui/material';
import { invoiceSelection } from './invoiceSelection';
import ConsultingInvoiceLayout from './layouts/consulting';
import CreativeInvoiceLayout from './layouts/creative';
import DefaultInvoiceLayout from './layouts/default';

export default function ClientView( {
	invoice,
	staff,
	timezone,
	layout,
	layoutColor,
	lineItemLayout,
	selectionsMenu,
}: {
	invoice: Order,
	selectionsMenu?: string[],
	staff?: Staff,
	timezone?: string,
	layout?: InvoiceLayoutTemplateType,
	layoutColor?: LayoutColorMode,
	lineItemLayout?: LineItemLayoutType
} ) {
	
	const { data } = useGraphQL<QueryInvoicesBalanceArgs>( {
		query    : InvoicesBalance,
		queryKey : [ 'invoicesBalance' ],
		variables: { clientId: invoice?.client?.id, invoiceId: invoice?.id, clientEmail: null, paid: true },
	}, { enabled: invoice?.type === 'INVOICE' && Boolean( invoice.client?.id ) } );
	
	const theme = useTheme();
	const themeBasedLayoutColor = layoutColor ? layoutColor[ theme.palette.mode ] : undefined;
	
	if ( !invoice ) return null;
	
	const selections = selectionsMenu ? [ ...selectionsMenu ] : invoice.company.metadata?.selectionsMenu || [];
	
	const modifiedInvoice = invoiceSelection( invoice, selections );
	
	const invoicesBalance = data?.invoicesBalance;
	
	if ( layout === 'consulting' ) {
		return (
			<ConsultingInvoiceLayout
				invoice={{ ...invoice, ...modifiedInvoice }}
				selectionsMenu={selections}
				staff={staff}
				timezone={timezone}
				invoicesBalance={invoicesBalance}
				themeBasedLayoutColor={themeBasedLayoutColor}
				lineItemLayout={lineItemLayout}
				themeMode={theme.palette.mode}
			/>
		);
	}
	
	if ( layout === 'creative' ) {
		return (
			<CreativeInvoiceLayout
				invoice={{ ...invoice, ...modifiedInvoice }}
				selectionsMenu={selections}
				staff={staff}
				timezone={timezone}
				invoicesBalance={invoicesBalance}
				themeBasedLayoutColor={themeBasedLayoutColor}
				lineItemLayout={lineItemLayout}
				themeMode={theme.palette.mode}
			/>
		);
	}
	
	return (
		<DefaultInvoiceLayout
			invoice={{ ...invoice, ...modifiedInvoice }}
			selectionsMenu={selections}
			staff={staff}
			timezone={timezone}
			invoicesBalance={invoicesBalance}
			themeBasedLayoutColor={themeBasedLayoutColor}
			lineItemLayout={lineItemLayout}
			themeMode={theme.palette.mode}
		/>
	);
}
