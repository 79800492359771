import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import { Box, Typography } from '@mui/material';

export default function Attachments( {
	attachments,
	layoutColor,
	isPdf,
}: {
	attachments: string[],
	layoutColor?: string,
	isPdf?: boolean
} ) {
	if ( !Boolean( attachments?.length ) ) return null;
	
	return (
		<PageSection
			primary='Attachments'
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			<Box sx={{ display: 'flex', flexWrap: 'wrap', p: 1 }}>
				{isPdf ? <Typography>Attachments included</Typography> : attachments.map( ( attachment, index ) => (
					<Attachment
						key={index}
						src={attachment}
						imageSX={{ objectFit: 'cover' }}
						sx={{ mr: 1, mb: 1 }}
					/>
				) )}
			</Box>
		</PageSection>
	);
}
