import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import { Grid, Typography, useTheme } from '@mui/material';
import type { Order } from '../../../../../types/schema';

export default function MergedSignatures( {
	order,
	layoutColor,
}: {
	order: Order,
	layoutColor?: string
} ) {
	const theme = useTheme();
	
	const signatures = order.metadata?.mergedSignatures;
	const themeBasedLayoutColor = layoutColor ? layoutColor[ theme.palette.mode ] : undefined;
	
	if ( !signatures ) return null;
	return (
		<PageSection
			primary='Signatures'
			primaryTypographyProps={{ variant: 'h5', color: themeBasedLayoutColor || undefined }}>
			<Grid container spacing={1}>
				{Object.keys( signatures ).map( ( orderNumber, index ) => (
					<Grid key={index} item xs={12} sm={3}>
						<Attachment
							removeDownload
							src={signatures[ orderNumber ]}
							imageSX={{
								width    : 'auto',
								height   : 60,
								objectFit: 'cover',
								mb       : 0,
							}}
						/>
						<Typography color='text.secondary'>
							#{orderNumber.substring( 0, 5 )}...
						</Typography>
					</Grid>
				) )}
			</Grid>
		</PageSection>
	);
}
