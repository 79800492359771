import PageLinkComponent from '@/components/page/linkComponent';
import PageSection from '@/components/page/section';
import TipTapTypography from '@/components/tiptap/readOnlyTipTap';
import type { Policy } from '@/types/schema';
import { Button } from '@mui/material';

export default function PolicyView( {
	policy,
	layoutColor,
}: {
	policy?: Policy,
	layoutColor?: string
} ) {
	if ( !policy || policy.policy.length < 1 ) return null;
	
	return (
		<PageSection
			primary=''
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			<TipTapTypography
				className='ellipsisText5Line'
				sx={{
					fontSize : '12px !important',
					overflow : 'hidden',
					textAlign: 'justify',
					color    : 'text.secondary',
				}}>
				{policy.policy}
			</TipTapTypography>
			{policy.policy.length > 350 && (
				<Button
					component={PageLinkComponent}
					target='_blank'
					color='primary'
					variant='text'
					sx={{ alignSelf: 'start' }}
					href={`/p/${policy.id}/policy`}>
					Read full policy
				</Button>
			)}
		</PageSection>
	);
}

