import PageSection from '@/components/page/section';
import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Notes( {
	notes,
	layoutColor,
	poClient,
	isPdf,
}: {
	notes: string,
	poClient?: string,
	layoutColor?: string,
	isPdf?: boolean
} ) {
	const { t } = useTranslation();
	
	if ( !poClient && !notes?.length ) return null;
	
	return (
		<PageSection
			primary={isPdf ? 'Notes' : t( 'common:notes' )}
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			<Typography sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
				{poClient}
			</Typography>
			{notes && (
				<Typography sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
					{poClient && <Divider sx={{ my: 1, borderStyle: 'dotted' }}/>}
					{notes}
				</Typography>
			)}
		</PageSection>
	);
}
