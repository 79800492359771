import StyledImage from '@/components/styledImage';
import { Store as StoreIcon } from '@mui/icons-material';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Company } from '../../../../../../types/schema';

export default function CreativeHeader( { company }: { company?: Company } ) {
	return (
		<Stack position='relative'>
			<StyledImage
				sx={{
					height      : '100%',
					maxHeight   : { xs: 120, sm: 150 },
					borderRadius: 2,
					border      : 1,
					borderColor : 'divider',
				}}
				alt='banner'
				src={company?.banner || 'https://assets.invoiss.com/banner.jpg'}
			/>
			<Box
				sx={{
					position    : 'absolute',
					width       : '100%',
					height      : '100%',
					borderRadius: 2,
				}}>
				<Typography
					sx={{
						position: 'absolute',
						bottom  : 0,
						pb      : 3,
						pr      : 10,
						pl      : 3,
						fontSize: { xs: 13, sm: 16 },
						color   : '#fff',
					}}>
				
				</Typography>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					left    : { xs: 20, sm: 20 },
					bottom  : -50,
				}}>
				<Avatar
					variant='circular'
					src={company?.logo}
					sx={{
						width       : 100,
						height      : 100,
						border      : 4,
						fontSize    : '10rem',
						borderColor : 'background.default',
						borderRadius: '50%',
						img         : { objectFit: 'contain', bgcolor: '#ffffff' },
					}}>
					<StoreIcon sx={{ fontSize: 55 }}/>
				</Avatar>
			</Box>
		</Stack>
	);
}
