import PageSection from '@/components/page/section';
import { CustomField } from '@/types/schema';
import { Grid, Typography } from '@mui/material';

export default function CustomFields( { customFields, title }: { customFields: CustomField[], title?: string } ) {
	
	if ( !customFields?.length ) return null;
	
	return (
		<PageSection primary={title || ''}>
			<Grid container spacing={1}>
				{customFields.map( ( { name, value }, index ) => (
					<Grid key={index} item xs={3}>
						<Typography sx={{ fontWeight: 'bold' }}>
							{name}
						</Typography>
						<Typography sx={{ color: 'text.secondary' }}>
							{value}
						</Typography>
					</Grid>
				) )}
			</Grid>
		</PageSection>
	);
}
