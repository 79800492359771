import { SxProps, Typography } from '@mui/material';
import { Fragment } from 'react';
import type { AddressBase, ClientWithAddressGateway, Order } from '../../../../types/schema';

export default function BillTo( {
	client,
	address,
	order,
	titleSX,
	nameSX,
	infoSX,
}: {
	client: ClientWithAddressGateway,
	address: AddressBase,
	order?: Order,
	fontSize?: number,
	titleSX?: SxProps,
	nameSX?: SxProps,
	infoSX?: SxProps
	
} ) {
	if ( !client || order?.metadata?.hideClientInfo ) return null;
	
	return (
		<Fragment>
			<Typography
				sx={{ color: 'text.secondary', ...titleSX }}>
				Bill To:
			</Typography>
			{client?.name && (
				<Typography variant='h3' sx={{ ...nameSX }}>
					{client.name}
				</Typography>
			)}
			{client && client?.contact !== client?.name && (
				<Typography sx={{ ...infoSX }}>
					{client.contact}
				</Typography>
			)}
			{client && client?.email && (
				<Typography sx={{ ...infoSX }}>
					{client.email}
				</Typography>
			)}
			{address && (
				<Fragment>
					<Typography sx={{ ...infoSX }}>
						{[ address.line1, address.line2 ].filter( Boolean ).join( ', ' )}
					</Typography>
					<Typography sx={{ ...infoSX }}>
						{[ address.city, address.state, address.country, address.postalCode ].filter( Boolean ).join( ', ' )}
					</Typography>
				</Fragment>
			)}
			{client?.phone && client && (
				<Typography sx={{ ...infoSX }}>
					{client?.phone?.replace( /^\+1\s*/, '' )}
				</Typography>
			)}
			{client?.cell && client && (
				<Typography sx={{ ...infoSX }}>
					{client?.cell?.replace( /^\+1\s*/, '' )}
				</Typography>
			)}
		</Fragment>
	);
}

