import { Order, Staff } from '@/types/schema';
import { LineItemLayoutType } from '@/types/templates';
import { Box, Grid, PaletteMode, Typography } from '@mui/material';
import React from 'react';
import Attachments from '../../../attachments';
import CustomFields from '../../../customFields';
import Notes from '../../../notes';
import PaymentHistory from '../../../payments';
import GetPricing from '../../../pricing';
import Signature from '../../../signature';
import BillTo from '../../billTo';
import CompanyInfo from '../../company';
import InvoiceInfo from '../../invoiceInfo';
import LineItemsClient from '../../lineItems';
import ShipTo from '../../shipTo';
import AgreementSection from '../agreementSection';
import PolicyView from '../policy';
import SignatureLine from '../signatureLine';
import CreativeHeader from './header';

export default function CreativeInvoiceLayout( {
	invoice,
	staff,
	timezone,
	themeBasedLayoutColor,
	lineItemLayout,
	invoicesBalance,
	selectionsMenu,
	themeMode,
}: {
	invoice: Order,
	selectionsMenu: string[],
	staff?: Staff,
	timezone?: string,
	invoicesBalance?: number,
	themeBasedLayoutColor: string,
	lineItemLayout?: LineItemLayoutType,
	themeMode?: PaletteMode
	
} ) {
	const pricing = GetPricing( invoice );
	
	return (
		<Grid
			container
			sx={{
				'.MuiListItem-divider': { borderColor: themeBasedLayoutColor },
				'.MuiTableCell-root'  : { fontSize: { xs: '12px', sm: '1.2333rem' } },
				'.MuiTypography-root' : { fontSize: { xs: '12px', sm: '1.2333rem' } },
				'p'                   : { xs: 2, sm: 3 },
			}}>
			<Grid item xs={12} pb={4}>
				<CreativeHeader company={invoice.company}/>
			</Grid>
			<Grid item xs={6} md={7} pt={3} px={1}>
				<Box>
					<CompanyInfo
						removeImage
						company={invoice.company}
						address={invoice.companyLocation?.address}
						staff={invoice?.staff}
						companyNameSX={{ fontSize: { sm: '1.7rem !important' }, fontWeight: 'bold' }}
						companyInfoSX={{ fontSize: { sm: '1.4rem' } }}
					/>
				</Box>
			</Grid>
			<Grid item xs={6} md={5}>
				<Typography
					sx={{
						fontWeight: '500',
						textAlign : 'right',
						fontSize  : { xs: '2.0848rem !important', sm: '3.0848rem !important' },
					}}>
					{invoice.type}
				</Typography>
				<InvoiceInfo
					removeTitle
					staff={staff}
					invoice={invoice}
					invoicesBalance={invoicesBalance}
					selectionsMenu={selectionsMenu}
					layout='creative'
				/>
			</Grid>
			{invoice?.title && (
				<Grid item xs={12} sx={{ textAlign: 'right' }}>
					<Typography>
						{invoice.title}
					</Typography>
				</Grid>
			)}
			{invoice?.client && (
				<Grid item sm={5} xs={6} md={7} my={3} px={1}>
					<BillTo
						client={invoice.client}
						address={invoice.clientAddress}
						titleSX={{ fontSize: '13px !important' }}
						nameSX={{ fontSize: '1.1333rem !important' }}
					/>
				</Grid>
			)}
			{invoice?.client && (
				<Grid item sm={5} xs={6} md={5} my={3}>
					<ShipTo
						client={invoice.client}
						address={invoice.shippingAddress}
						titleSX={{ fontSize: '13px !important' }}
						nameSX={{ fontSize: '1.1333rem !important' }}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<LineItemsClient
					removeTableBorder
					selectionsMenu={selectionsMenu}
					invoice={invoice}
					pricing={pricing}
					layoutColor={themeBasedLayoutColor}
					invoicesBalance={invoicesBalance}
					lineItemLayout={lineItemLayout}
					themeMode={themeMode}
				/>
			</Grid>
			<Grid item xs={12}>
				<Signature
					invoice={invoice}
					signature={invoice.metadata.signature}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
			<Grid item xs={12}>
				<PaymentHistory
					invoice={invoice}
					timezone={timezone}
					payments={invoice.payments}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomFields customFields={invoice?.customFields}/>
			</Grid>
			<Grid item xs={12}>
				<Notes
					notes={invoice.notes}
					poClient={invoice?.client?.po}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
			<Grid item xs={12}>
				<Attachments
					attachments={invoice.attachments}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
			{invoice.metadata?.signatureLine && (
				<Grid item xs={12}>
					<SignatureLine
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<AgreementSection
					agreements={invoice.agreements}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
			<Grid item xs={12}>
				<PolicyView
					policy={invoice.policy}
					layoutColor={themeBasedLayoutColor}
				/>
			</Grid>
		</Grid>
	);
}
