import { Order, Staff } from '@/types/schema';
import { LineItemLayoutType } from '@/types/templates';
import { Box, Grid, PaletteMode, Typography } from '@mui/material';
import { startCase, toLower } from 'lodash-es';
import React from 'react';
import Attachments from '../../../attachments';
import CustomFields from '../../../customFields';
import Notes from '../../../notes';
import MergedSignatures from '../../../order/order/mergedSignatures';
import PaymentHistory from '../../../payments';
import GetPricing from '../../../pricing';
import Signature from '../../../signature';
import BillTo from '../../billTo';
import CompanyInfo from '../../company';
import InvoiceInfo from '../../invoiceInfo';
import LineItemsClient from '../../lineItems';
import ShipTo from '../../shipTo';
import AgreementSection from '../agreementSection';
import PolicyView from '../policy';
import SignatureLine from '../signatureLine';

export default function ConsultingInvoiceLayout( {
	themeBasedLayoutColor,
	invoice,
	staff,
	timezone,
	invoicesBalance,
	lineItemLayout,
	selectionsMenu,
	themeMode,
}: {
	invoice: Order,
	selectionsMenu: string[],
	staff?: Staff,
	timezone?: string,
	invoicesBalance?: number,
	themeBasedLayoutColor: string | undefined,
	lineItemLayout?: LineItemLayoutType,
	themeMode?: PaletteMode
} ) {
	const pricing = GetPricing( invoice );
	
	return (
		<Grid
			container
			sx={{
				'.MuiListItem-divider': { borderColor: themeBasedLayoutColor },
				'.MuiTableCell-root'  : { fontSize: { xs: '12px', sm: '1.2333rem' } },
				'.MuiTypography-root' : { fontSize: { xs: '12px', sm: '1.2333rem' } },
			}}>
			<Grid item xs={12}>
				<Grid
					item
					container
					sx={{
						bgcolor: themeBasedLayoutColor,
						px     : { xs: 2, sm: 4 },
						py     : 3,
					}}>
					<Grid
						item
						xs={4}
						sx={{
							display       : 'flex',
							flexDirection : 'column',
							alignItems    : 'start',
							justifyContent: 'start',
						}}>
						<Typography
							sx={{
								textAlign : 'right',
								fontWeight: '500',
								fontSize  : { xs: '2.0848rem !important', sm: '3.0848rem !important' },
							}}
							variant='h1'>
							{startCase( toLower( invoice.type ) )}
						</Typography>
					</Grid>
					<Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end' }}>
						<Box>
							<CompanyInfo
								removeImage
								company={invoice.company}
								address={invoice.companyLocation?.address}
								staff={invoice?.staff}
								layout='consulting'
								companyNameSX={{
									fontSize      : { sm: '1.7rem !important' },
									fontWeight    : '500',
									textAlign     : 'right',
									justifyContent: 'end',
								}}
								companyInfoSX={{ fontSize: { sm: '1.4rem', textAlign: 'right' } }}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container p={{ xs: 2, sm: 3 }}>
				<Grid item xs={6} md={7} px={1} pt={3}>
					<Box mb={2}>
						<BillTo
							client={invoice.client}
							address={invoice.clientAddress}
							titleSX={{ fontSize: '13px !important' }}
							nameSX={{ fontSize: '1.2333rem !important' }}
						/>
					</Box>
					<ShipTo
						client={invoice.client}
						address={invoice.shippingAddress}
						titleSX={{ fontSize: '13px !important' }}
						nameSX={{ fontSize: '1.2333rem !important' }}
					/>
				</Grid>
				<Grid item xs={6} md={5} pr={{ xs: 0, sm: 1 }} pt={3}>
					<InvoiceInfo
						removeTitle
						staff={staff}
						invoice={invoice}
						invoicesBalance={invoicesBalance}
						selectionsMenu={selectionsMenu}
						layout='consulting'
					/>
				</Grid>
				{invoice?.title && (
					<Grid item xs={12} sx={{ textAlign: 'right' }}>
						<Typography>
							{invoice.title}
						</Typography>
					</Grid>
				)}
				<Grid item xs={12}>
					<LineItemsClient
						removeTableBorder
						selectionsMenu={selectionsMenu}
						invoice={invoice}
						pricing={pricing}
						layoutColor={themeBasedLayoutColor}
						invoicesBalance={invoicesBalance}
						lineItemLayout={lineItemLayout}
						themeMode={themeMode}
					/>
				</Grid>
				<Grid item xs={12}>
					<MergedSignatures order={invoice} layoutColor={themeBasedLayoutColor}/>
				</Grid>
				<Grid item xs={12}>
					<Signature
						invoice={invoice}
						signature={invoice.metadata.signature}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
				<Grid item xs={12}>
					<PaymentHistory
						invoice={invoice}
						timezone={timezone}
						payments={invoice.payments}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomFields customFields={invoice?.customFields}/>
				</Grid>
				<Grid item xs={12}>
					<Notes
						notes={invoice.notes}
						poClient={invoice?.client?.po}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
				<Grid item xs={12}>
					<Attachments
						attachments={invoice.attachments}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
				{invoice.metadata?.signatureLine && (
					<Grid item xs={12}>
						<SignatureLine
							layoutColor={themeBasedLayoutColor}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<AgreementSection
						agreements={invoice.agreements}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
				<Grid item xs={12}>
					<PolicyView
						policy={invoice.policy}
						layoutColor={themeBasedLayoutColor}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
