import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import { Order } from '@/types/schema';

export default function Signature( {
	signature,
	invoice,
	layoutColor,
}: {
	signature: string,
	invoice: Order,
	layoutColor?: string
} ) {
	if ( !signature?.length || invoice.metadata?.mergedSignatures ) return null;
	
	return (
		<PageSection
			primary='Signature'
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			<Attachment
				removeDownload
				src={signature}
				imageSX={{ width: 100, objectFit: 'cover', mb: 0, border: 'none' }}
			/>
		</PageSection>
	);
}
