import CreditCardBrandImage from '@/components/creditCardBrandImage';
import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import currencyFormat from '@/helpers/currencyFormat';
import { getPaymentAndAdditionalCharge } from '@/pages/dashboard/commerce/fragments-[id]-view/payments/utils';
import type { Order, Payment } from '@/types/schema';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty, startCase, toLower } from 'lodash-es';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentStatus } from './paymentStatuses';

export default function PaymentHistory( {
	payments,
	timezone,
	layoutColor,
	isPdf,
	invoice,
}: {
	payments: Payment[],
	timezone?: string,
	layoutColor?: string,
	isPdf?: boolean,
	invoice?: Order
} ) {
	const { t } = useTranslation();
	
	const hiddenPayments = invoice?.metadata?.clientHiddenPayments;
	
	const modifiedPayments = !isEmpty( hiddenPayments )
		? payments?.filter( ( payment ) => !hiddenPayments[ payment.externalId || payment.id ] )
		: payments;
	const invoissPayments = modifiedPayments?.filter( ( payment ) => ![
		'invoiss',
		'haps' ].includes( toLower( payment.type ) ) );
	
	if ( !Boolean( invoissPayments?.length ) ) return null;
	
	return (
		<PageSection
			primary={isPdf ? 'Payments' : t( 'common:payments' )}
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			{invoissPayments.map( ( payment, index ) => {
				const { totalWithTip, paymentAmount } = getPaymentAndAdditionalCharge( payment );
				return (
					<Fragment key={index}>
						<Stack spacing={2} direction='row' justifyContent='space-between' px={1}>
							<Box>
								<Stack spacing={1} direction='row'>
									{payment.amount > 0 && (
										<Typography>
											{currencyFormat( paymentAmount )}
										</Typography>
									)}
									{payment.tip > 0 && (
										<Typography sx={{ color: 'text.secondary' }}>
											{`${isPdf ? 'Tip' : t( 'commerce:tip' )}: ${currencyFormat( payment.tip )}`}
										</Typography>
									)}
								</Stack>
								{( payment.type || payment.checkNumber ) && (
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography>
											{startCase( toLower( payment.type ) )} {payment.checkNumber}
										</Typography>
										{payment.type && <CreditCardBrandImage type={payment.type} brand={payment.cardBrand}/>}
										{payment.cardLast4 && (
											<Typography color='text.secondary'>
												***{payment.cardLast4}
											</Typography>
										)}
									</Stack>
								)}
								{payment.externalId && (
									<Typography color='text.secondary'>
										{payment.externalId}
									</Typography>
								)}
								{payment.refundedAmount > 0 && (
									<Typography>
										{isPdf
											? 'Refunded'
											: `${t( 'common:refunded' )}: ${currencyFormat( payment.refundedAmount )}`}
									</Typography>
								)}
								{!isEmpty( payment?.prices )
									? (
										<Stack spacing={0.5}>
											{payment.prices.map( ( price, index ) => (
												<Typography key={index} color='text.secondary'>
													Charge: {price?.name} {price.isPercent
													? `(${price.value}%) ${currencyFormat( totalWithTip * ( price.value / 100 ) )}`
													: `(${currencyFormat( price.value / 100 )})`}
												</Typography>
											) )}
										</Stack>
									) : null}
							</Box>
							<Stack sx={{ textAlign: 'right' }}>
								{paymentStatus[ payment.status ] && (
									<Tooltip title={paymentStatus[ payment.status ][ 2 ]}>
										<Typography
											fontWeight='bold'
											color={paymentStatus[ payment.status ][ 1 ]}>
											{paymentStatus[ payment.status ][ 0 ]}
										</Typography>
									</Tooltip>
								)}
								<Typography color='text.secondary' sx={{ mt: 0 }}>
									{timezone
										? formatInTimeZone( payment.createdAt, timezone || null, 'PPp' )
										: format( payment.createdAt, 'PPp' )}
								</Typography>
								{payment?.signature && (
									<Attachment
										key='signature'
										removeDownload
										src={payment.signature}
										imageSX={{
											width    : 150,
											border   : 'none',
											height   : 'unset',
											objectFit: 'cover',
										}}
									/>
								)}
							</Stack>
						</Stack>
						{payment.note && (
							<Typography sx={{ pl: 1 }} color='text.secondary'>
								{payment.note}
							</Typography>
						)}
						{index !== modifiedPayments.length - 1 && <Divider sx={{ my: 1 }}/>}
					</Fragment>
				);
			} )}
		</PageSection>
	);
}
