import { SxProps, Typography } from '@mui/material';
import { Fragment } from 'react';
import type { AddressBase, ClientWithAddressGateway } from '../../../../types/schema';

export default function ShipTo( {
	client,
	address,
	titleSX,
	nameSX,
	infoSX,
}: {
	client: ClientWithAddressGateway,
	address: AddressBase,
	titleSX?: SxProps,
	nameSX?: SxProps,
	infoSX?: SxProps
} ) {
	return address && (
		<Fragment>
			<Typography
				sx={{ color: 'text.secondary', ...titleSX }}>
				Deliver To:
			</Typography>
			{client?.name && !address.fullName && (
				<Typography variant='h3' sx={{ ...nameSX }}>
					{client.name}
				</Typography>
			)}
			{address && (
				<Fragment>
					<Typography variant='h3' sx={{ ...infoSX }}>
						{address.fullName || ''}
					</Typography>
					<Typography sx={{ ...infoSX }}>
						{address.email || ''}
					</Typography>
					<Typography sx={{ ...infoSX }}>
						{[ address.line1, address.line2 ].filter( Boolean ).join( ', ' )}
					</Typography>
					<Typography sx={{ ...infoSX }}>
						{[ address.city, address.state, address.country, address.postalCode ].filter( Boolean ).join( ', ' )}
					</Typography>
					<Typography sx={{ ...infoSX }}>
						{address.phone || ''}
					</Typography>
				</Fragment>
			)}
		</Fragment>
	);
}
